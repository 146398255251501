import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import logo from "../../YX-logo-01.png";
class Header extends React.Component {
  render() {
    return (
      <Navbar bg="" expand="lg" className="color-nav">
        <Navbar.Brand href="#">
          <img
            src={logo}
            height="45"
            className="d-inline-block align-top header_img"
            alt="Sunmow logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#">
              <h4>YX PRECIOUS METALS BHD</h4>
              <small className="registration_header">
                {" "}
                202101001245 (1401543-M){" "}
              </small>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}
export default Header;
